<template>
    <v-container class="py-8 px-6 pl-12" fluid>
        <v-card outlined style="border: 0px solid white" class="mt-n10">
            <v-card-text class="mt-n3">
                <div class="cont-title" style="float: left;">주소록</div>
            </v-card-text>
        </v-card>

        <v-row class="mt-16">
            <v-col class="mb-0">
                <router-view></router-view>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>

export default {
    name: 'svc_block_mdn',
    components: {
    },
    data: () => ({
    }),
    created () {
        this.$store.commit('layoutFlag', true)
        this.$store.commit('menuId', 'addr_book')
    },
    methods: {
    }
}
</script>

